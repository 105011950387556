.step404 {
  background: #fff;
  width: 100vw;
  height: 67vw;
  position: relative;
  padding-left: 5vw;
  top: 0; }
  @media (max-width: 1024px) {
    .step404 {
      background-position: 63% 0;
      height: 71vh; } }
  .step404.img404 {
    background: url(../Images/404-error.svg) no-repeat bottom right/cover; }
    @media (max-width: 768px) {
      .step404.img404 {
        background-position: 70%; } }
  .step404 .error {
    flex-direction: column;
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    top: 5vw; }
    @media (max-width: 768px) {
      .step404 .error {
        top: 7%; } }
    .step404 .error .titulo {
      margin: 1.5vw;
      font-size: 4vw;
      line-height: 2.7vw;
      color: #050505; }
    .step404 .error .subtitulo {
      margin: 0vw 2vw 2vw;
      font-size: 4vw;
      line-height: 4.7vw;
      color: #050505;
      font-weight: bold;
      font-family: "quicksand-bold" !important; }
      @media (max-width: 768px) {
        .step404 .error .subtitulo {
          font-size: 2.5em;
          line-height: 1em; } }
    .step404 .error .btn-2 {
      border-radius: 100px;
      height: 4vw;
      width: auto;
      font-size: 2vw;
      padding: 0 2vw;
      margin: 0vw 2vw 2vw;
      background: #00e5ba; }
      @media (max-width: 768px) {
        .step404 .error .btn-2 {
          font-size: 21px;
          height: 43px;
          padding: 0 11%; } }
